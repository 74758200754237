<template>
    <div id="chat-container" class="chatbot-container">
        <div id="chat-messages">
            <div class="message" v-for="(message, index) in messages" :key="index">{{ message }}</div>
        </div>
        <div id="user-input">
            <input type="text" placeholder="메시지를 입력하세요..." v-model="userMessage" @keyup.enter="sendMessage" />
            <button @click="sendMessage">전송</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'openAI',
    data() {
        return {
            messages: [],
            userMessage: ''
        };
    },
    methods: {
        sendMessage() {
            const message = this.userMessage.trim();
            if (message.length === 0) return;

            this.addMessage('나', message);
            this.userMessage = ''; // 입력 필드를 비웁니다.

            this.send(message);
        },
        addMessage(sender, message) {
            this.messages.unshift(`${sender}: ${message}`);
        },
        async send(msg) {
            this.axiosCaller.get(this, this.APIs.ASK_BOT + "/chat", { 'msg' : msg }, (res) => {
                let result = res.data;

                if (result.status === "ok") {
                    this.addMessage('WAPU', result.botResponse);

                } else {
                    console.error("send() Error!!");
                    this.addMessage('WAPU', "error");
                }
            });
        },
    }
}
</script>

<style scoped>
body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}
.message {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    background-color: #f0f0f0;
}
#chat-container {
    width: 400px;
    height: 600px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
}
#chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
}
#user-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
}
#user-input input {
    flex: 1;
    padding: 10px;
    outline: none;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
}
#user-input button {
    border: none;
    background-color: #1e88e5;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
}
</style>

